import React, { useContext, useState, useEffect } from "react";
import Store from "store";



export function Editor({block}) {
  const { state, dispatch } = useContext(Store);
  const [ isReveal, setIsReveal ] = useState(false);
  const [ refBlock, setRefBlock ] = useState();

  // Reveal the block with animation after specific delay
  //
  useEffect(() => {
    setTimeout(() => {
      if (refBlock !== undefined) {
        setIsReveal(true);
        dispatch({ type: "RESPONSE_ADD", payload: block });
        dispatch({ type: "FORM_NEXT" });
      }
    }, (block.props.delay || 0.5) * 1000);
  }, [refBlock]);


  return (
    <li className={`block-${block.align}`}>
      <div
        className={
          `block-${block.type} editor` +
          (isReveal  ? ' reveal' : '')
        }
        ref={(e) => setRefBlock(e)}
      >
        {block.props.text}
      </div>
    </li>
  )

}


export function Message({block}) {
  const { state, dispatch } = useContext(Store);
  const [ isReveal, setIsReveal ] = useState(false);
  const [ refBlock, setRefBlock ] = useState();

  /*
  useEffect(() => {
    let delay = 750;
    //if (! isNaN(parseInt(block.props.delay))) {
    //  delay = parseInt(block.props.delay) * 1000;
    //}

    if (refBlock !== undefined) {
      setIsReveal(true);

      setTimeout(() => {
        dispatch({ type: "FORM_NEXT" });
      }, (block.props.delay || 0.5) * 1000 );
    }

  }, [refBlock]);
  */

  useEffect(() => {
    setTimeout(() => {
      if (refBlock !== undefined) {
        setIsReveal(true);
        dispatch({ type: "FORM_NEXT" });
      }
    }, 1);
  }, [refBlock]);


  const replacer = (match, p1, p2, p3, offset, string) => {
    return state.user.var[p2] || '';
  }

  return (
    <div
      className={`block-${block.type} message` + (isReveal ? ' reveal' : '')}
      ref={(e) => setRefBlock(e)}
    >
      {block.content.replace(new RegExp('(\{\{)(.+?)(\}\})', 'gi'), replacer)}
    </div>
  )

}

