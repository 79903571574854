import React, { useContext, useState, useEffect } from "react";
import Store from "store";


export function Editor({block}) {
  const { state, dispatch } = useContext(Store);
  const [ isReveal, setIsReveal ] = useState(false);
  const [ refBlock, setRefBlock ] = useState();


  // Reveal the block with animation after specific delay
  //
  useEffect(() => {
    setTimeout(() => {
      if (refBlock !== undefined) {
        setIsReveal(true);
        dispatch({ type: "FORM_NEXT" });
      }
    }, 750);
  }, [refBlock]);


  return (
    <li className={`block-${block.align}`}>
      <div
        className={
          `block-${block.type} editor` +
          (isReveal  ? ' reveal' : '')
        }
        ref={(e) => setRefBlock(e)}
      >
        {block.props.items.map( (item, index) => (
          <a key={index} href={item.url}>{item.icon}</a>
        ))}
      </div>
    </li>
  )

}



export function Message({block}) {
  const { state, dispatch } = useContext(Store);
  const [ isReveal, setIsReveal ] = useState(false);
  const [ refBlock, setRefBlock ] = useState();


  useEffect(() => {
    setTimeout(() => {
      if (refBlock !== undefined) {
        setIsReveal(true);
        dispatch({ type: "FORM_NEXT" });
      }
    }, 1);
  }, [refBlock]);


  const linkClicked = (e) => {
    return false;

    let name = e.target.getAttribute('class');
    name = name.replace('fi-', '');

    const msg = {
      type: 'tag',
      align: 'right',
      action: 'click',
      content: name,
    }

    dispatch({ type: "RESPONSE_ADD", payload: msg });
    dispatch({ type: "RESPONSE_DIRTY", payload: true });
  }

  return (
    <div
      className={`block-${block.type} message` + (isReveal ? ' reveal' : '')}
      ref={(e) => setRefBlock(e)}
    >
      {block.props.items.map( (item, index) => (
        <a className={`icon-${item.icon} fi-${item.icon}`} key={index} href={item.url} target="_blank" onClick={linkClicked}></a>
      ))}
    </div>
  )

}
