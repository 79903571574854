import React from "react";

// Store Context is the global context that is managed by reducers.

let url = {
  do: "https://do.formito.com",
  storage: "https://storage.googleapis.com/formitoups/"
};

let ga_tracking_id = "UA-73325209-12";

if (process.env.NODE_ENV !== "production") {
  url = {
    do: "http://localhost:3001",
    storage: "http://api.formito.test/storage/"
  };

  ga_tracking_id = "";
}

const app = {
  ready: false,
  ga_tracking_id: ga_tracking_id,
  url
};

const user = {
  uuid: "",
  id: 0,
  ip: "",
  url: "",
  response: [],
  var: {},
  dirty: false
};

const form = {
  cover: {},
  pointer: -1
};

const Store = React.createContext({
  app,
  form,
  user
});

export default Store;
