
export default function app(state={}, {type, payload}) {
    switch (type) {
      case "APP_READY":
        return {
          ...state,
          ready: true
        };

      case "APP_NOT_READY":
        return {
          ...state,
          ready: false
        };

      case "APP_GA_TRACKING_ID":
        return {
          ...state,
          ga_tracking_id: payload,
        };

      default:
        return state;
    }
  }
