import app from './app';
import form from './form';
import user from './user';


export default function reducer(state, action) {
  //console.log(action.type);
  return {
    ...state,
    app:  app(state.app, action),
    form: form(state.form, action),
    user: user(state.user, action),
  }

}
