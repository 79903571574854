import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import Store from "store";
import PerfectScrollbar from "react-perfect-scrollbar";
import ReactPixel from "react-facebook-pixel";
import TwitterConvTrkr from "react-twitter-conversion-tracker";
import { Messages, Response } from "./index";
import { ReactComponent as ArrowBack } from "icon/ion-md-arrow-round-back.svg";

let pingTimeout = null;

export default function Chatbox({ match }) {
  const { state, dispatch } = useContext(Store);
  const [isLoading, setIsLoading] = useState(true);
  const [is404, setIs404] = useState(false);
  const [isPosting, setIsPosting] = useState(false);
  const [pointerBlock, setPointerBlock] = useState(undefined);
  const [refPS, setRefPS] = useState(undefined);
  const [shrinkCover, setShrinkCover] = useState(false);
  const [ping, setPing] = useState(false);

  useEffect(() => {
    if (ping) {
      if (state.user.id > 0) {
        const data = {
          id: state.user.id,
          ping: true,
        };

        //axios.post("/do/response", data);
        setPing(false);
      }
    }
  }, [ping]);

  const postResponse = (isComplete = false) => {
    if (!isComplete && isPosting) {
      return;
    }

    if (!state.user.var || Object.keys(state.user.var).length == 0) {
      return;
    }

    setIsPosting(true);

    var data = {
      id: state.user.id,
      form_id: state.form.id,
      url: state.user.url,
      completed: isComplete,
      data: {
        //chat: state.user.response,
        pairs: state.user.var,
      },
    };

    axios
      .post("/do/response", data)
      .then((response) => {
        dispatch({ type: "RESPONSE_SET_ID", payload: response.data.id });
        dispatch({ type: "RESPONSE_DIRTY", payload: false });

        // Cancel ping timeout and reset the time, so we don't send ping if we sent a message
        //
        clearInterval(pingTimeout);
        // Stop sending ping after form completion
        if (!isComplete) {
          pingTimeout = setInterval(() => {
            setPing((prev) => !prev);
          }, 29000);
        }
      })
      .catch((error) => {
        //console.log(error);
      })
      .then(() => {
        setIsPosting(false);
      });
  };

  function scrollTo(element, to = 0, duration = 1000, scrollToDone = null) {
    const start = element.scrollTop;
    const change = to - start;
    const increment = 20;
    let currentTime = 0;

    const animateScroll = () => {
      currentTime += increment;
      const val = Math.easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      } else {
        if (scrollToDone) scrollToDone();
      }
    };

    animateScroll();
  }

  Math.easeInOutQuad = function (t, b, c, d) {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const scrollToBottom = () => {
    if (refPS !== undefined) {
      if (refPS.scrollTo) {
        refPS.scrollTo({
          top: refPS.scrollHeight,
          left: 0,
          behavior: "smooth",
        });
      } else {
        refPS.scrollTop = refPS.scrollHeight;
      }

      if (!shrinkCover && refPS.scrollTop > 0) {
        setShrinkCover(true);
      }
    }
  };

  useEffect(() => {
    if (state.user.dirty) {
      postResponse();
    }
  }, [state.user.dirty]);

  useEffect(() => {
    scrollToBottom();
  }, [state.form.pointer, state.user.dirty]);

  // Sync form onload
  //
  useEffect(() => {
    axios
      .get(`/do/form/${match.params.slug}`)
      .then((response) => {
        if (response.data === "error") {
          setIs404(true);
          setIsLoading(false);
          return;
        }

        var def_settings = {
          popup: {},
          landing: {},
          notification: {
            email_each_response: false,
          },
          connect: {
            mailchimp: {},
            ga: {},
            facebook: {},
            twitter: {},
          },
          design: {
            progressbar: false,
          },
        };

        response.data["settings"] = Object.assign(
          {},
          def_settings,
          response.data["settings"]
        );

        window.ga("create", state.app.ga_tracking_id, "auto", { name: "GA" });
        window.ga("GA.send", "pageview");

        if (response.data.settings.tracking_id.ga) {
          window.ga("create", response.data.settings.tracking_id.ga, "auto", {
            name: "UserGA",
          });
          window.ga("UserGA.send", "pageview");
        }

        if (response.data.settings.tracking_id.fb) {
          ReactPixel.init(response.data.settings.tracking_id.fb);
          ReactPixel.pageView();
        }

        if (response.data.settings.tracking_id.tw) {
          TwitterConvTrkr.init(response.data.settings.tracking_id.tw);
          TwitterConvTrkr.pageView();
        }

        if (response.data.settings.design.primary_color) {
          var LightenColor = function (color, percent) {
            var num = parseInt(color.replace("#", ""), 16),
              amt = Math.round(2.55 * percent),
              R = (num >> 16) + amt,
              B = ((num >> 8) & 0x00ff) + amt,
              G = (num & 0x0000ff) + amt;

            return (
              0x1000000 +
              (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
              (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
              (G < 255 ? (G < 1 ? 0 : G) : 255)
            )
              .toString(16)
              .slice(1);
          };

          let color = response.data.settings.design.primary_color;
          let color_light = "#" + LightenColor(color, 25);
          let css = `
        a, a:hover,
        .block-links.message a:after {
          color: ${color};
        }
        .chat-cover, .chat-cover::before,
        .chat-progressbar,
        .block-multiple_choice.message .option,.block-select.message .option,.block-single_choice.message .option,
        .block-appointment.message,
        .block-reply, .block-input.message, .block-date.message, .block-number.message, .block-short_text.message, .block-long_text.message, .block-email.message {
          background-color: ${color};
        }
        .block-input.editor.has-focus, .block-short_text.editor.has-focus, .block-long_text.editor.has-focus, .block-number.editor.has-focus, .block-date.editor.has-focus, .block-appointment.editor.has-focus, .block-email.editor.has-focus {
          border-color: ${color_light}
        }
        .btn-send:hover,
        .block-input.editor.has-focus button, .block-short_text.editor.has-focus button, .block-long_text.editor.has-focus button, .block-number.editor.has-focus button, .block-date.editor.has-focus button, .block-appointment.editor.has-focus button, .block-email.editor.has-focus button {
          background-color: ${color_light} !important;
          fill: ${color};
        }

        .block-links.message a:hover,
        .block-multiple_choice.editor a:hover,.block-select.editor a:hover,.block-single_choice.editor a:hover {
          color: ${color};
          border-color: ${color};
        }
        .block-multiple_choice.editor a.active,.block-select.editor a.active,.block-single_choice.editor a.active {
          background-color: ${color};
          border-color: ${color};
          color: #fff;
        }
        `,
            head = document.head || document.getElementsByTagName("head")[0],
            style = document.createElement("style");

          head.appendChild(style);

          style.type = "text/css";
          if (style.styleSheet) {
            style.styleSheet.cssText = css;
          } else {
            style.appendChild(document.createTextNode(css));
          }
        }

        dispatch({ type: "FORM_SYNC", payload: response.data });
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });

    window.addEventListener("resize", () => {
      scrollToBottom();
    });
  }, []);

  // Update page title
  //
  useEffect(() => {
    if (state.form.cover.title) {
      document.title = state.form.cover.title + " — Formito";
    } else if (state.form.name) {
      document.title = state.form.name + " — Formito";
    }
  }, [state.form.cover]);

  // Go next
  //
  useEffect(() => {
    setPointerBlock(undefined);

    const next = () => {
      if (state.form.blocks === undefined) {
        return;
      }
      const block = state.form.blocks[state.form.pointer];

      if (block === undefined) {
        setPointerBlock(undefined);
        postResponse(true);

        /*
        if (state.form.slug !== 'vd') {
          let ad = {
            type: 'formitoad',
            align: 'left',
            content: '',
          }
          dispatch({ type: "RESPONSE_ADD", payload: ad });
        }
        */

        return;
      }

      let res = {};

      switch (block.type) {
        case "jump":
          var labelIndex = -1;
          state.form.blocks.map((b, index) => {
            if (b.type === "label" && b.name === block.props.to) {
              labelIndex = index;
            }
          });

          if (labelIndex > -1) {
            dispatch({ type: "FORM_SET_POINTER", payload: labelIndex });
            return;
          }

          dispatch({ type: "FORM_NEXT" });
          return;

        case "label":
        case "cover":
        case "hidden":
          /*
          res = {
            type: 'label',
            align: 'left',
            content: 'LBL: '+ block.props.text,
          }
          dispatch({ type: "RESPONSE_ADD", payload: res });
          */
          dispatch({ type: "FORM_NEXT" });
          return;

        case "variable":
          dispatch({
            type: "VARIABLE_SAVE",
            payload: {
              key: block.name,
              value: block.props.value,
              dirty: false,
            },
          });
          dispatch({ type: "FORM_NEXT" });
          return;

        case "assign":
          let value = state.user.var[block.props.variable] || "";
          let value2 = block.props.value;

          const value2Replacer = (match, p1, p2, p3, offset, string) => {
            return state.user.var[p2] || "";
          };

          if (value2.indexOf("{{") >= 0) {
            value2 = value2.replace(
              new RegExp("({{)(.+?)(}})", "gi"),
              value2Replacer
            );
          }

          const value2Int = isNaN(parseInt(value2)) ? 0 : parseInt(value2);

          switch (block.props.action) {
            case "set":
              value = value2;
              break;
            case "add":
              value = parseInt(value) + value2Int;
              break;
            case "subtract":
              value = parseInt(value) - value2Int;
              break;
            case "multiply":
              value = parseInt(value) * value2Int;
              break;
            case "divide":
              if (parseInt(value2) !== 0) {
                value = parseInt(value) / parseInt(value2);
              } else {
                value = 0;
              }
              break;
          }

          dispatch({
            type: "VARIABLE_SAVE",
            payload: { key: block.props.variable, value: value },
          });
          dispatch({ type: "FORM_NEXT" });
          return;

        case "text":
          res = {
            type: "text",
            align: "left",
            content: block.props.text,
          };

          setTimeout(() => {
            dispatch({ type: "RESPONSE_ADD", payload: res });
          }, (block.props.delay || 0.5) * 1000);
          return;
          break;

        case "social":
        case "links":
        case "accordion":
        case "navigation":
        case "image":
          setTimeout(() => {
            dispatch({ type: "RESPONSE_ADD", payload: block });
          }, (block.props.delay || 0.5) * 1000);
          return;
          break;

        case "tag":
          return;
      }

      setPointerBlock(block);

      setTimeout(() => {
        scrollToBottom();
      }, (block.props.delay || 0.5) * 1000 + 100);
    };

    next();
  }, [state.form.pointer]);

  if (isLoading) {
    return (
      <div className="spinner-wrapper">
        <div className="spinner-grow text-info" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  if (is404) {
    return (
      <div className="card card-center">
        <h5>404 — Nothing Found</h5>
        <br />
        <p style={{ marginBottom: 0 }}>
          The page you're looking for doesn't exist.{" "}
          <a href="https://formito.com">Return to Formito</a>.
        </p>
      </div>
    );
  }

  let url = new URL(window.location.href);
  let GET_parent = url.searchParams.get("parent");

  const goBackTo = (slug) => {
    window.location.replace(state.app.url.do + "/e/" + slug);
  };

  function nl2br(str, is_xhtml) {
    var breakTag =
      is_xhtml || typeof is_xhtml === "undefined" ? "<br " + "/>" : "<br>";
    return (str + "").replace(
      /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
      "$1" + breakTag + "$2"
    );
  }

  function strip(html) {
    return html;
    var doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  }

  const brandUrl =
    "https://formito.com?utm_source=" +
    state.user.url +
    "&utm_medium=launcher&utm_campaign=referral";

  return (
    <>
      {state.form.settings.landing.title && (
        <div className="landing-info">
          <h1>{state.form.settings.landing.title}</h1>
          <div
            className="landing-info-content"
            dangerouslySetInnerHTML={{
              __html: state.form.settings.landing.text,
            }}
          />
        </div>
      )}

      <div className="main-container">
        {state.form.cover.props.visible && (
          <div className={"chat-cover" + (shrinkCover ? " is-shrink" : "")}>
            {GET_parent && (
              <span
                className="back"
                title="Back"
                onClick={(e) => goBackTo(GET_parent)}
              >
                <ArrowBack />
              </span>
            )}
            <h6>{state.form.cover.props.title}</h6>
            <p>{state.form.cover.props.subtitle}</p>
          </div>
        )}

        <PerfectScrollbar
          className="chatbox-wrapper"
          options={{ wheelSpeed: 0.4 }}
          containerRef={(e) => setRefPS(e)}
        >
          <ol className="chatbox">
            <Messages />
            <Response block={pointerBlock} />

            {isLoading && (
              <li className="block-left">
                <div className="block-loading">
                  <div className="dot-pulse" />
                </div>
              </li>
            )}
          </ol>
        </PerfectScrollbar>

        {!state.form.settings.design.remove_branding && (
          <a className="brand" href={brandUrl} target="_blank">
            ⚡ By Formito
          </a>
        )}

        {state.form.settings.design.progressbar && (
          <div
            className="chat-progressbar"
            style={{
              width:
                (state.form.pointer / state.form.blocks.length) * 100 + "%",
            }}
          />
        )}
      </div>
    </>
  );
}
