import React, { useContext } from "react";
import Store from "store";
import * as Block from "blocks";

export default function Response({ block }) {
  //const { state, dispatch } = useContext(Store);

  if (block === undefined) {
    return null;
  }

  const type = block.type.charAt(0).toUpperCase() + block.type.slice(1);
  const DynamicBlock = Block[`Block${type}`];

  if (!DynamicBlock) {
    return null;
  }

  return <DynamicBlock block={block} />;
}
