import React, { useState, useContext, useReducer, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ReactDOM from "react-dom";
import Analytics from 'react-router-ga';
import Store from "./store";
import reducer from "./reducer";
import axios from 'axios';

import { Chatbox } from './form/index';

import 'react-perfect-scrollbar/dist/css/styles.css';
import './scss/app.scss';

axios.defaults.baseURL = 'https://fbtest-27dd8.appspot.com/v1';
if (process.env.NODE_ENV !== 'production') {
  axios.defaults.baseURL = 'http://api.formito.test/v1';
}


const NotFound = () => (
  <div className="card card-center">
    <h5>404 — Nothing Found</h5>
    <br />
    <p style={{marginBottom: 0}}>The page you're looking for doesn't exist. <a href="https://formito.com">Return to Formito</a>.</p>
  </div>
)


function App() {
  const globalStore = useContext(Store);
  const [state, dispatch] = useReducer(reducer, globalStore);

  useEffect( () => {

    dispatch({ type: "RESPONSE_SET_URL", payload: document.location.href });
    window.addEventListener('message', function(msg) {
      dispatch({ type: "RESPONSE_SET_URL", payload: msg.data.url });

      if (msg.data.predefined_variables) {
        if (msg.data.predefined_variables.id) {
          delete msg.data.predefined_variables.id;
        }
        dispatch({ type: "PREDEFINED_VARIABLES_SAVE", payload: msg.data.predefined_variables });
      }
    })

    document.body.className += (window.self == window.top ? " no-iframed" : " iframed");
    /*
    var url = (window.location != window.parent.location) ? document.referrer : document.location.href;
    //console.log(document.location.ancestorOrigins[0]);
    dispatch({ type: "RESPONSE_SET_URL", payload: url });
    */
  }, []);

  //console.log(window.parent.document.URL);

  return (
    <Store.Provider value={{ state, dispatch }}>
      <Router>
        {/*<Analytics id={state.app.ga_tracking_id} basename="DO" trackPathnameOnly>*/}
          <Switch>
            <Route path="/e/:slug" component={Chatbox} />
            <Route component={NotFound}/>
          </Switch>
        {/*</Analytics>*/}
      </Router>
    </Store.Provider>
  );
}


const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
