import React, { useContext, useState, useEffect } from "react";
import Store from "store";
import * as Block from "blocks";

export default function Messages() {
  const { state, dispatch } = useContext(Store);

  let markup = state.user.response.map( (block, index) => {
    if (block.type === 'tag') {
      return;
    }
    const type = block.type.charAt(0).toUpperCase() + block.type.slice(1);
    const DynamicBlock = Block[`Block${type}Message`];
    return (
      <li className={`block-${block.align}`} key={index}>
        <DynamicBlock block={block} />
      </li>
    )
  });

  return markup;

}
