import React, { useContext, useState, useEffect } from "react";
import Store from "store";


export function Editor({block}) {
  const { state, dispatch } = useContext(Store);
  const [ isReveal, setIsReveal ] = useState(false);
  const [ refBlock, setRefBlock ] = useState();


  // Reveal the block with animation after specific delay
  //
  useEffect(() => {
    setTimeout(() => {
      if (refBlock !== undefined) {
        setIsReveal(true);
        dispatch({ type: "FORM_NEXT" });
      }
    }, 750);
  }, [refBlock]);


  return (
    <li className={`block-${block.align}`}>
      <div
        className={
          `block-${block.type} editor` +
          (isReveal  ? ' reveal' : '')
        }
        ref={(e) => setRefBlock(e)}
      >
        {block.props.items.map( (item, index) => (
          <div key={index} className="accordion-item">
            <a href="#">{item.title}</a>
            <div>{item.text}</div>
          </div>
        ))}
      </div>
    </li>
  )

}


export function Message({block}) {
  const { state, dispatch } = useContext(Store);
  const [ isReveal, setIsReveal ] = useState(false);
  const [ refBlock, setRefBlock ] = useState();
  const [ activeIndex, setActiveIndex ] = useState(-1);

  useEffect(() => {
    setTimeout(() => {
      if (refBlock !== undefined) {
        setIsReveal(true);
        dispatch({ type: "FORM_NEXT" });
      }
    }, 1);
  }, [refBlock]);

  const linkClicked = (e) => {
    return false;

    const msg = {
      type: 'tag',
      align: 'right',
      action: 'click',
      content: e.target.innerText,
    }

    dispatch({ type: "RESPONSE_ADD", payload: msg });
    dispatch({ type: "RESPONSE_DIRTY", payload: true });
    return false;
  }


  const changeActiveAccordion = (index) => {
    if (activeIndex === index) {
      setActiveIndex(-1);
    }
    else {
      setActiveIndex(index);
    }
  }


  return (
    <div
      className={`block-${block.type} message` + (isReveal ? ' reveal' : '')}
      ref={(e) => setRefBlock(e)}
    >
      {block.props.items.map( (item, index) => (
        <div key={index} className={"accordion-item" + (activeIndex === index ? ' active' : '')}>
          <a className="accordion-title" href="#" onClick={() => changeActiveAccordion(index)}>{item.title}</a>
          <div className="accordion-content" dangerouslySetInnerHTML={{__html: item.text }} />
        </div>
      ))}
    </div>
  )

}
