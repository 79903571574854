
export default function form(state={}, {type, payload}) {
  switch (type) {

    case 'FORM_SYNC':
      return {
        id: payload.id,
        slug: payload.slug,
        name: payload.name,
        blocks: payload.data.blocks,
        cover: payload.data.blocks[0],
        settings: payload.settings,
        pointer: 0,
      };

    case 'FORM_CLEAR':
      return {};

    case 'FORM_NEXT':
      let pointer = 0;
      if (state.pointer !== undefined) {
        pointer = state.pointer+1;
      }

      return {
        ...state,
        pointer,
      };

    case 'FORM_SET_POINTER':
      return {
        ...state,
        pointer: payload
      };

    default:
      return state;
  }
}

