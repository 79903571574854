import React, { useContext, useState, useEffect } from "react";
import Store from "store";
import { ReactComponent as IconSend } from 'icon/ion-send.svg';


export function Editor({block}) {
  const { state, dispatch } = useContext(Store);
  const [ isReveal, setIsReveal ] = useState(false);
  const [ refBlock, setRefBlock ] = useState();
  const [ choices, setChoices ] = useState([]);


  // Reveal the block with animation after specific delay
  //
  useEffect(() => {
    setTimeout(() => {
      if (refBlock !== undefined) {
        setIsReveal(true);
      }
    }, (block.props.delay || 0.5) * 1000 );
  }, [refBlock]);


  const toggleChoice = (item) => {
    setChoices(choices.includes(item) ? choices.filter(i => i !== item) : [...choices, item]);
  }


  const save = (content, jump) => {
    const msg = {
      type: block.type,
      align: block.align,
      content: content,
    }
    dispatch({ type: "RESPONSE_ADD", payload: msg });
    dispatch({ type: "VARIABLE_SAVE", payload: {key: block.name, value: msg.content} });

    if (jump) {
      var labelIndex = -1;

      state.form.blocks.map( (block, index) => {
        if (block.type === 'label' && block.name === jump) {
          labelIndex = index;
        }
      })

      if (labelIndex > -1) {
        dispatch({ type: "FORM_SET_POINTER", payload: labelIndex });
        return;
      }
    }

    /*
    if (block.jump !== undefined && block.jump[index] !== undefined) {
      var labelName = block.jump[index];
      var labelIndex = -1;

      state.form.blocks.map( (block, index) => {
        if (block.type === 'label' && block.props.text === labelName) {
          labelIndex = index;
        }
      })

      if (labelIndex > -1) {
        dispatch({ type: "FORM_SET_POINTER", payload: labelIndex });
        return;
      }
    }
    */

    dispatch({ type: "FORM_NEXT" });
  }

  return (
    <li className={`block-${block.align}`}>
      <div
        className={
          `block-${block.type} editor` +
          (isReveal  ? ' reveal' : '')
        }
        ref={(e) => setRefBlock(e)}
      >
        {!block.props.multiple && block.props.items.map( (item, index) => (
          <a className="option" key={index} href="#" onClick={(e) => save( item.text, item.jump)}>{item.text}</a>
        ))}

        {block.props.multiple && block.props.items.map( (item, index) => (
          <a className={"option" + (choices.indexOf(item.text) > -1 ? ' active' : '')} key={index} href="#" onClick={() => toggleChoice(item.text)}>{item.text}</a>
        ))}

        {block.props.multiple &&
          <div className="option submit">
            <button className="btn-send" onClick={() => save(choices)} />
          </div>
        }

      </div>
    </li>
  )

}

export function Message({block}) {
  let content = block.content;

  if (content === '') {
    content = 'N/A';
  }

  if (Array.isArray(content)) {
    content = content.map((item, index) => <span className="option" key={index}>{item}</span>);
    return <div className={`block-${block.type} message`}>{content}</div>
  }

  return (
    <div className={`block-${block.type} message`}><span className="option">{content}</span></div>
  )

}
