import React, { useContext, useState, useEffect } from "react";
import Store from "store";



export function Editor({block}) {
  const { state, dispatch } = useContext(Store);
  const [ isReveal, setIsReveal ] = useState(false);
  const [ refBlock, setRefBlock ] = useState();

  // Reveal the block with animation after specific delay
  //
  useEffect(() => {
    setTimeout(() => {
      if (refBlock !== undefined) {
        setIsReveal(true);
        dispatch({ type: "RESPONSE_ADD", payload: block });
        dispatch({ type: "FORM_NEXT" });
      }
    }, (block.props.delay || 0.5) * 1000);
  }, [refBlock]);


  return (
    <li className={`block-${block.align}`}>
      <div
        className={
          `block-${block.type} editor` +
          (isReveal  ? ' reveal' : '')
        }
        ref={(e) => setRefBlock(e)}
      >
        {block.props.text}
      </div>
    </li>
  )

}


export function Message({block}) {
  const { state, dispatch } = useContext(Store);
  const [ isReveal, setIsReveal ] = useState(false);
  const [ refBlock, setRefBlock ] = useState();


  /*
  useEffect(() => {
    let delay = 750;
    //if (! isNaN(parseInt(block.props.delay))) {
    //  delay = parseInt(block.props.delay) * 1000;
    //}

    if (refBlock !== undefined) {
      setIsReveal(true);

      setTimeout(() => {
        dispatch({ type: "FORM_NEXT" });
      }, (block.props.delay || 0.5) * 1000 );
    }

  }, [refBlock]);
  */

  useEffect(() => {
    setTimeout(() => {
      if (refBlock !== undefined) {
        setIsReveal(true);
      }
    }, 1000);
  }, [refBlock]);

  var brandUrl = 'https://formito.com?utm_source='+ window.location.host +'&utm_medium=widget&utm_campaign=referral';

  return (
    <>
      <hr style={{borderTopColor: 'rgba(0,0,0,0.05)', marginTop: '3rem'}} />
      <a
        className={`block-${block.type} message` + (isReveal ? ' reveal' : '')}
        ref={(e) => setRefBlock(e)}
        href={brandUrl}
        target="_blank"
      >
        <h6>Build Your Own</h6>
        <p>Did you like it? You can build one in 3 minutes. <mark>Click here</mark> to get started.</p>
      </a>
    </>

  )

}
