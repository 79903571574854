import React, { useContext, useState, useEffect, useRef } from "react";
import Store from "store";
import moment from "moment";
import "flatpickr/dist/themes/airbnb.css";
import Flatpickr from "react-flatpickr";
import { ReactComponent as IconSend } from "icon/ion-send.svg";
import AppointmentPicker from "appointment-picker";
import "appointment-picker/dist/appointment-picker.css";

export function Editor({ block }) {
  const { state, dispatch } = useContext(Store);
  const [text, setText] = useState(block.props.default || "");
  const [textDate, setTextDate] = useState("");
  const [textTime, setTextTime] = useState("");
  const [isInvalid, setIsInvalid] = useState(false);
  const [isReveal, setIsReveal] = useState(false);
  const [hasFocus, setHasFocus] = useState(false);
  const [refBlock, setRefBlock] = useState();
  const refAppoPicker = useRef(null);

  // Reveal the block with animation after specific delay
  //
  useEffect(() => {
    setTimeout(() => {
      if (refBlock !== undefined) {
        setIsReveal(true);
      }
    }, (block.props.delay || 0.5) * 1000);
  }, [refBlock]);

  useEffect(() => {
    if (refAppoPicker) {
      let options = {
        leadingZero: true,
        interval: parseInt(block.props.time_interval),
        minTime: parseInt(block.props.start_hour),
        maxTime: parseInt(block.props.end_hour),
        startTime: parseInt(block.props.start_hour),
        endTime: parseInt(block.props.end_hour)
        //disabled: ['16:30', '17:00'],
      };
      let picker = new AppointmentPicker(refAppoPicker.current, options);
      refAppoPicker.current.addEventListener(
        "change.appo.picker",
        onTimeSelect
      );
    }
  }, [refAppoPicker]);

  const onTimeSelect = event => {
    setTextTime(event.displayTime);
  };

  const save = () => {
    if (!validate()) {
      setIsInvalid(true);
      setTimeout(() => {
        setIsInvalid(false);
      }, 2000);
      return;
    }

    let content = textDate;
    if (textTime) {
      content += " — " + textTime;
    }

    const msg = {
      cid: block.id || "",
      type: block.type,
      align: block.align,
      content: content
      /*
      appointment: {
        date: textDate,
        time: textTime,
      }
      */
    };

    dispatch({ type: "RESPONSE_ADD", payload: msg });
    dispatch({
      type: "VARIABLE_SAVE",
      payload: { key: block.name, value: msg.content }
    });
    dispatch({ type: "FORM_NEXT" });
  };

  const validate = () => {
    if (
      (textDate === "" || textTime === "") &&
      block.validation.required === true
    ) {
      return false;
    }

    return true;
  };

  const onKeyDown = e => {
    if (e.key === "Enter") {
      save();
    }
  };

  const onSkip = () => {
    setText("");
    save();
  };

  return (
    <li className={`block-${block.align}`}>
      <div
        className={
          `block-${block.type} editor` +
          (isInvalid ? " is-invalid" : "") +
          (hasFocus ? " has-focus" : "") +
          (isReveal ? " reveal" : "")
        }
        ref={e => setRefBlock(e)}
      >
        <Flatpickr
          options={{
            static: true,
            dateFormat: "F d, Y",
            position: "auto",
            minDate: "today",
            //maxDate: new Date().fp_incr(14)
            enable: [
              function(date) {
                return block.props.available_days["day_" + date.getDay()];
              }
            ]
          }}
          value={textDate}
          placeholder={block.props.placeholder_date}
          onChange={(date, dateStr) => {
            setTextDate(dateStr);
          }}
          onFocus={() => setHasFocus(true)}
          onBlur={() => setHasFocus(false)}
        />
        <input
          className="time-slots"
          type="text"
          readOnly
          placeholder={block.props.placeholder_time}
          onFocus={() => setHasFocus(true)}
          onBlur={() => setHasFocus(false)}
          ref={refAppoPicker}
        />
        <button className="btn-send" onClick={save} />
      </div>

      {/* appendTo: document.getElementsByClassName('main-container')[0]
      <div className="skip">
        <a href="#" onClick={onSkip}>Skip</a>
      </div>
      */}
    </li>
  );
}

export function Message({ block }) {
  let content = block.content;

  if (content === "") {
    content = "N/A";
  }

  return <div className={`block-${block.type} message`}>{content}</div>;
}
