export default function user(state = {}, { type, payload }) {
  switch (type) {
    // Variable
    //
    case "VARIABLE_SAVE":
      if (!payload.key) {
        return state;
      }

      return {
        ...state,
        dirty: "dirty" in payload ? payload.dirty : true,
        var: {
          ...state.var,
          [payload.key]: payload.value
        }
      };
    /*
      if (state.var && state.var[payload.key]) {
        let prev_value = state.var[payload.key];
        if (typeof prev_value === 'string') {
          prev_value = [prev_value];
        }

        return {
          ...state,
          dirty: true,
          var: {
            ...state.var,
            [payload.key]: [...prev_value, payload.value],
          }
        };
      }
      else {
        return {
          ...state,
          dirty: true,
          var: {
            ...state.var,
            [payload.key]: payload.value,
          }
        };
      }
      */

    case "PREDEFINED_VARIABLES_SAVE":
      return {
        ...state,
        //dirty: true,
        var: {
          ...state.var,
          ...payload
        }
      };

    // Response
    //
    case "RESPONSE_ADD":
      return {
        ...state,
        response: [...state.response, payload]
      };

    case "RESPONSE_SET_URL":
      return {
        ...state,
        url: payload
      };

    case "RESPONSE_SET_ID":
      return {
        ...state,
        id: payload
      };

    case "RESPONSE_DIRTY":
      return {
        ...state,
        dirty: payload
      };

    default:
      return state;
  }
}
