import React, { useContext, useState, useEffect } from "react";
import Store from "store";


export function Message({block}) {
  const { state, dispatch } = useContext(Store);
  const [ isReveal, setIsReveal ] = useState(false);
  const [ refBlock, setRefBlock ] = useState();

  useEffect(() => {
    let delay = 750;
    if (! isNaN(parseInt(block.delay))) {
      delay = parseInt(block.delay);
    }

    if (refBlock !== undefined) {
      setIsReveal(true);

      setTimeout(() => {
        dispatch({ type: "FORM_NEXT" });
      }, delay);
    }

  }, [refBlock]);

  return (
    <div
      className={`block-${block.type} message` + (isReveal ? ' reveal' : '')}
      ref={(e) => setRefBlock(e)}
    >
      {block.content}
    </div>
  )

}
