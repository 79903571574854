import React, { useContext, useState, useEffect } from "react";
import Store from "store";
import { ReactComponent as IconSend } from 'icon/ion-send.svg';


export function Editor({block}) {
  const { state, dispatch } = useContext(Store);
  const [ text, setText ] = useState(block.props.default || '');
  const [ isInvalid, setIsInvalid ] = useState(false);
  const [ isReveal, setIsReveal ] = useState(false);
  const [ hasFocus, setHasFocus ] = useState(false);
  const [ refBlock, setRefBlock ] = useState();


  // Reveal the block with animation after specific delay
  //
  useEffect(() => {
    setTimeout(() => {
      if (refBlock !== undefined) {
        setIsReveal(true);
      }
    }, (block.props.delay || 0.5) * 1000 );
  }, [refBlock]);


  useEffect(() => {
    if (state.user.var && block.name !== '' && block.name in state.user.var) {
      setText(state.user.var[block.name]);
    }
  }, [])


  const save = () => {
    if (!validate()) {
      setIsInvalid(true);
      setTimeout(() => {
        setIsInvalid(false);
      }, 2000);
      return;
    }

    const msg = {
      type: block.type,
      align: block.align,
      content: text,
    }

    dispatch({ type: "RESPONSE_ADD", payload: msg });
    dispatch({ type: "VARIABLE_SAVE", payload: {key: block.name, value: msg.content} });
    dispatch({ type: "FORM_NEXT" });
  }

  const validate = () => {

    if (text === '' && block.validation.required === true) {
      return false;
    }

    return true;
  }

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      save();
    }
  }

  const onSkip = () => {
    setText('');
    save();
  }



  return (
    <li className={`block-${block.align}`}>
      <div
        className={
          `block-${block.type} editor` +
          (isInvalid ? ' is-invalid' : '') +
          (hasFocus  ? ' has-focus' : '') +
          (isReveal  ? ' reveal' : '')
        }
        ref={(e) => setRefBlock(e)}
      >
        <input
          type="number"
          autoFocus
          placeholder={block.props.placeholder}
          min={block.props.min}
          max={block.props.max}
          step={block.props.step}
          value={text}
          onChange={ (e) => setText(e.target.value) }
          onKeyDown={onKeyDown}
          onFocus={() => setHasFocus(true)}
          onBlur={() => setHasFocus(false)}
        />
        <button className="btn-send" onClick={save} />
      </div>

      {/*
      <div className="skip">
        <a href="#" onClick={onSkip}>Skip</a>
      </div>
      */}
    </li>
  )

}


export function Message({block}) {
  let content = block.content;

  if (content === '') {
    content = 'N/A';
  }

  return (
    <div className={`block-${block.type} message`}>{content}</div>
  )
}
