import React, { useContext, useState, useEffect } from "react";
import Store from "store";


export function Editor({block}) {
  const { state, dispatch } = useContext(Store);
  const [ isReveal, setIsReveal ] = useState(false);
  const [ refBlock, setRefBlock ] = useState();


  // Reveal the block with animation after specific delay
  //
  useEffect(() => {
    setTimeout(() => {
      if (refBlock !== undefined) {
        setIsReveal(true);
        dispatch({ type: "FORM_NEXT" });
      }
    }, 750);
  }, [refBlock]);


  return (
    <li className={`block-${block.align}`}>
      <div
        className={
          `block-${block.type} editor` +
          (isReveal  ? ' reveal' : '')
        }
        ref={(e) => setRefBlock(e)}
      >
        {block.props.items.map( (item, index) => (
          <a key={index} href={item.url}>{item.text}</a>
        ))}
      </div>
    </li>
  )

}


export function Message({block}) {
  const { state, dispatch } = useContext(Store);
  const [ isReveal, setIsReveal ] = useState(false);
  const [ refBlock, setRefBlock ] = useState();

  useEffect(() => {
    setTimeout(() => {
      if (refBlock !== undefined) {
        setIsReveal(true);
        dispatch({ type: "FORM_NEXT" });
      }
    }, 1);
  }, [refBlock]);

  const linkClicked = (slug) => {
    if (!slug) {
      return false;
    }
    let url = state.app.url.do + '/e/'+ slug + '?parent=' + state.form.slug;
    //window.location.replace(url);
    window.location.href = url;
  }

  return (
    <div
      className={`block-${block.type} message` + (isReveal ? ' reveal' : '')}
      ref={(e) => setRefBlock(e)}
    >
      {block.props.items.map( (item, index) => (
        <div key={index} className="link-item" onClick={e => linkClicked(item.slug)}>
          <h6>{item.title}</h6>
          {item.text && <p>{item.text}</p>}
        </div>
      ))}
    </div>
  )

}
